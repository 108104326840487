import React, { Children } from 'react';
import { constants } from '@wsb/guac-widget-core';
import brick from 'bricks.js';
import PropTypes from 'prop-types';
import { debounce, uniqueId } from 'lodash';

const { MD_MIN } = constants.breakpoints;

const BRICKS_CONTAINER_ID_PREFIX = 'bricks-container-';

class Masonry extends React.Component {
  constructor() {
    super(...arguments);

    this.loadCount = 0;
    this.itemCount = 0;
    this.bricksContainerId = uniqueId(BRICKS_CONTAINER_ID_PREFIX);

    this.handleResize = debounce(this.handleResize.bind(this), 150);
    this.handleLoad = this.handleLoad.bind(this);
  }

  initializeBricks() {
    const { device, renderMode, sizes, container = `#${ this.bricksContainerId }` } = this.props;

    return brick({
      position: false,
      container,
      packed: 'data-packed',
      // For mobile preview, we need to force the smallest sizes breakpoint
      sizes: renderMode === 'PREVIEW' && device === 'mobile' ? [sizes[0]] : sizes
    });
  }

  addEventToChildren() {
    return Children.map(this.props.children, elem => (
      React.cloneElement(elem, {
        onLoad: this.handleLoad,
        onError: this.handleLoad
      })
    ));
  }

  componentDidMount() {
    const { renderMode, device, onLoad, container } = this.props;

    this.bricksInstance = this.initializeBricks();

    if (renderMode === 'PREVIEW' && device === 'mobile') {
      this.bricksInstance.pack();
    }

    if (onLoad) {
      this.bricksInstance.once('pack', onLoad);
    }

    if (container) {
      // Container was passed in, so keep a reference to the DOM node
      this.container = typeof container === 'string' ? document.querySelector(container) : container;
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.device !== this.props.device) {
      this.bricksInstance.pack();
    } else {
      const childrenLength = this.getChildrenLength();
      if (childrenLength < this.itemCount) {
        // when an image is deleted
        this.bricksInstance.pack();
        this.itemCount = childrenLength;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleLoad() {
    const childrenLength = this.getChildrenLength();

    this.loadCount++;

    if (this.itemCount === 0 && this.loadCount === childrenLength || childrenLength === this.itemCount) {
      // call pack() when:
      // an image is moved
      // or
      // all images finish loading (usually happens when page load)
      this.bricksInstance.pack();
      this.itemCount = childrenLength;
      this.loadCount = 0;
    } else if (childrenLength === this.itemCount + this.loadCount) {
      // call update() when adding new images. update() will only calculate position for images that are unpacked
      this.bricksInstance.update();
      this.itemCount = childrenLength;
      this.loadCount = 0;
    }
  }

  handleResize() {
    if (this.bricksInstance) {
      if (this.container) {
        this.container.style.width = '100%';
      }

      this.bricksInstance.pack();
    }
  }

  getChildrenLength() {
    return Children.count(this.props.children);
  }

  render() {
    const { container } = this.props;

    return container ? (
      <>
        { this.addEventToChildren() }
      </>
    ) : (
      <div
        id={ this.bricksContainerId }
        ref={ el => {
          this.container = el;
        } }
      >
        { this.addEventToChildren() }
      </div>
    );
  }
}

Masonry.propTypes = {
  children: PropTypes.array,
  device: PropTypes.string,
  sizes: PropTypes.array,
  imageRefs: PropTypes.array,
  renderMode: PropTypes.string,
  container: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onLoad: PropTypes.func
};

Masonry.defaultProps = {
  sizes: [
    { columns: 2, gutter: 0 },
    { mq: `${MD_MIN}px`, columns: 3, gutter: 0 }
  ]
};

export default Masonry;
